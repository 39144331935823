import { BitgetConnector } from '@/services/web3/connectors/bitget/bitget.connector';
import { handleDependencyError } from '..';

let _bitgetConnector;

/**
 * Uses the real Bitget connector instance by default but allows injecting Bitget connector mocks from tests
 */
export async function initBitgetConnector() {
  if (!_bitgetConnector) {
    // Lazy load dependency to reduce initial bundle size
    const { BitgetConnector } = await import(
      '@/services/web3/connectors/bitget/bitget.connector'
    );
    _bitgetConnector = BitgetConnector;
  }
}

export async function initBitgetConnectorForTesting(bitgetConnector) {
  _bitgetConnector = bitgetConnector;
}

export function getBitgetConnector(): BitgetConnector {
  if (!_bitgetConnector) {
    handleDependencyError('BitgetConnector');
  }
  return _bitgetConnector;
}
